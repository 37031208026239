import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { first } from 'rxjs/operators';
import { ConnectionService } from 'ng-connection-service';
import configClariti from '../../../assets/data/config.json';
import { AuthenticationService } from '../../services/authentication.service';
import { ERR_MESSAGES } from '../../shared/constants/error-messages.constants';
import { GlobalErrorService } from '../../shared/services/global-error.service';
import { Okta } from '../../shared/okta.service';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import { WellService } from 'src/app/services/well.service';
import { UserService } from 'src/app/services/user.service';
import { OktaAuthService } from '@okta/okta-angular';
import { clearLocalStorage } from 'src/app/shared/utilities/util.js';
import {PRIVACY_POLICY_URL} from 'src/app/shared/constants/master-page.constants';


interface Job {
  AllLogs: boolean,
  Customer: string,
  JobName: string,
  JobRequestName: string,
  LogUids: string[],
  PSLName: string,
  UnitSet: string,
  WellName: string,
  WellUid: string
}
interface User {
  email: string,
  displayName: string,
  IWJob?: Job[]
}

@Component({
  selector: 'app-login-page',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [MessageService],
})
export class LoginPageComponent implements OnInit {
  configClariti = configClariti;
  modal: { open: boolean, type: string } = {
    open: false,
    type: '',
  };
  isChecked: boolean;
  remember: boolean;
  name: string;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  version = '';
  policyURl = '';
  copyRightYear: number;
  passwordResetLink = '';
  user: any;
  username = '';
  password: any;
  isLocal = false;
  isLoggedIn: boolean = false;
  oktaSignIn: any;
  isAuthenticated: boolean = false;
  enableTerms: boolean = false;
  isEntitlements: boolean;
  errorMessage: undefined;
  privacyLink: string;
  data: any;
  constructor(
    @Inject('ENV_CONFIG') private envConfig: any,
    private okta: Okta,
    public oktaAuth: OktaAuthService,
    private router: Router,
    private connectionService: ConnectionService,
    private authenticationService: AuthenticationService,
    private globalErrorService: GlobalErrorService,
    private messageService: MessageService,
    private wellService: WellService,
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.privacyLink = PRIVACY_POLICY_URL;
    try {
      this.oktaSignIn = this.okta.getWidget();
    } catch (error) {
      this.showLoginError(error.message);
    }

    if (localStorage.getItem('currentUser')) {
      this.router.navigate(['']);
    }

    this.connectionService.monitor().subscribe((isConnected) => {
      if (isConnected) {
        this.messageService.clear('ToastMessageKeyNetwork');
      } else {
        const error = new Error();
        error.message = ERR_MESSAGES.NO_CONNECTIVITY;
        this.messageService.clear('ToastMessageKeyNetwork');
        this.showNetworkError(error);
      }
    });


  }

  async ngOnInit() {
    // subscribe okta authentication status from authentication service
    this.authenticationService.currentAuthState.subscribe((authState) => {
      this.isAuthenticated = authState;
    })

    try {
      // For non-federated account redirection, token is parsed from url
      if (this.oktaSignIn.hasTokensInUrl()) {
        this.loading = true;
        this.oktaSignIn.authClient.token.parseFromUrl().then((tokens: any) => {
          // Save the tokens for later use, e.g. if the page gets refreshed:
          // Add the token to tokenManager to automatically renew the token when needed
          let oktaTokens = tokens.tokens;
          if (oktaTokens) {
            this.setTokenAndCurrentUser(oktaTokens);
            this.getRoles();
            this.getUserEntitlements();
          }
        }).catch((err: any) => {
          console.log(err);
        }
        );
      } else { // for federated account redirection
        this.oktaSignIn.authClient.session.get().then((response: any) => {
          if (response.status === configClariti.appconstants.userStatusActive && !this.isAuthenticated) {
            this.changeDetectorRef.detectChanges();
            this.loading = true;
            this.oktaSignIn.authClient.token.getWithoutPrompt({
              clientId: this.envConfig.clientId, 
              responseType: ["token", "id_token"],
              scopes: ["openid", "profile", "email"]
            }).then((tokens: any) => {
              if (tokens.tokens) {
                this.setTokenAndCurrentUser(tokens.tokens);
                this.getRoles();
                this.getUserEntitlements();
              }
            }).catch((err) => {
              // Fallback to getWithRedirect if getWithoutPrompt fails
              this.oktaSignIn.authClient.token.getWithRedirect({
                clientId: this.envConfig.clientId,
                responseType: ["token", "id_token"],
                scopes: ["openid", "profile", "email"]
              });
            });
          } else if (this.isAuthenticated) {
            this.loading = true;
            this.getUserEntitlements();
            this.router.navigate(['app']);
          } else {
            clearLocalStorage();
            this.oktaAuth.tokenManager.clear();
            this.changeDetectorRef.detectChanges();
            this.router.navigate(['login']);
            this.showLogin();
          }
        }).catch((err) => {
          console.log(err);
          this.showLogin();
        });
      }
    } catch (error) {
      console.log(error);
      this.showLogin();
    }

    this.version = this.envConfig.version;
    this.copyRightYear = new Date().getFullYear();
    this.policyURl = configClariti.appconstants.privacyURL;
    this.passwordResetLink = configClariti.appconstants.passwordResetLink;
  }

  showLogin(): void {
    this.loading = false;
    this.enableTerms = false;
    try {
      this.oktaSignIn.renderEl({ el: '#okta-login-container' },
        (response: any) => {
          //callback control doesnt come back here after okta authentication
          if (response.status === 'SUCCESS') {
            alert("inside success");
          }
        });
        this.enableTerms = true;
    } catch (err) {
      this.enableTerms = false;
      console.log(err);
    }
  }

  setTokenAndCurrentUser(tokens: any) {
    let idToken = tokens.idToken && tokens.idToken;
    let accessToken = tokens.accessToken && tokens.accessToken;
    this.oktaSignIn.authClient.tokenManager.add('accessToken', accessToken);
    this.oktaSignIn.authClient.tokenManager.add('idToken', idToken);
    const userClaims = idToken.claims;
    let claimName = userClaims.name;
    this.username = claimName.split(/\s(.+)/)[0];
    let userEmail = userClaims.email;
    const currentUser: User = {
      email: userEmail,
      displayName: this.username,
      IWJob: []
    }
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
    this.authenticationService.getUOMProfiles();
  }

  async getRoles(){
    this.userService.getRoles().subscribe((data: any) => {}),
    ((err)=>{
      console.log(`error in fetching roles: ${err}`);
    });
  }

  async getUserEntitlements() {
    this.wellService.getUserJobList().subscribe((result) => {
      if (result) {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        currentUser['IWJob'] = [];
        result['IWJob'].forEach(well => {
          if (well.wellUid) {
            currentUser['IWJob'].push({
              jobName: well.jobName,
              JobId: well.jobId,
              UnitSet: well.unitSet,
              wellName: well.wellName,
              wellUid: well.wellUid,
            });
          }
        })
        this.isEntitlements = currentUser.IWJob && currentUser.IWJob.length > 0 ? true : false;
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
        this.router.navigate(['app']);
      }
    }, (error) => {
      if (error.error) {
        if (error.error.Error) {
          this.error = error.error.Error.Message;
        } else {
          this.showLoginError(ERR_MESSAGES.error500);
        }
      } else {
        this.showLoginError(error.message);
      }
    });

    this.globalErrorService.get401Error().pipe(first()).subscribe((errors) => {
      if (errors) {
        this.isEntitlements = false;
        // special case in 401 message check
        if (errors.message === 'User ' + this.username + ' is not in either Halamerica or XNET!.') {
          errors.message = 'User not registered. Please check with RTO support';
        }
        this.showLoginError(errors.message);
      }
    });

    this.globalErrorService.get5xxError().pipe(first()).subscribe((errors) => {
      if (errors) {
        this.isEntitlements = false;
        this.showLoginError(errors.message);
      }
    });

  }

  showNetworkError(error) {
    this.messageService.clear('ToastMessageKeyNetwork');
    this.messageService.add({
      key: 'ToastMessageKeyNetwork',
      severity: 'error',
      summary: error.message,
      closable: false,
      detail: '',
      sticky: true
    });
  }

  openModal(id: string) {
    this.modal.type = id;
    this.modal.open = !this.modal.open;
  }

  closeModal(event: any) {
    this.modal.open = !this.modal.open;
    this.modal.type = '';
  }
  checkValue(event: any) {
    this.isChecked = event;
  }

  hideLoginError() {
    this.authenticationService.logoutCompletelyRedirectToLogin();
    this.messageService.clear();
  }

  showLoginError(message) {
    this.messageService.clear('loginError');
    this.errorMessage = message;
    this.messageService.add({
      key: 'loginError',
      severity: 'error',
      summary: this.errorMessage,
      closable: false,
      detail: '',
      sticky: true
    });
    this.loading = false;
  }
}
